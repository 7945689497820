import React, {useState} from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {AddMoreCreatorsModal} from './AddMoreCreatorsModal';
import {openCalendlyPopup} from '../../../../shared/functions/Functions';
import {useScreenResize} from '../../../../shared/hooks/useScreenResize';

interface IAgencyCardItemPlaceholderProps {
    openModal: () => void;
}
export function AgencyCardItemPlaceholder({openModal}: IAgencyCardItemPlaceholderProps) {
    const {t} = useTranslation();
    const {width} = useScreenResize();
    const isBigScreen = width > 1600;

    return (
        <>
            <div className={`col-md-6 col-lg-4 ${isBigScreen && 'col-xl-3'} d-flex align-items-stretch`}>
                <Card className="brand-card d-flex height-386 justify-content-center align-items-center py-5 px-3">
                    <img className="w-fit-content" src={'/tempAssets/img-add-agency.png'}
                         alt="Add creators"
                    />
                    <button type="button"
                            // onClick={() => openCalendlyPopup('https://calendly.com/creator-success-team/creator-management-onboarding-call?month=2024-03')}
                        onClick={openModal}
                            className="btn btn-primary text-uppercase w-fit-content mt-5">
                        {t('agency.add_more_creators')}
                    </button>
                </Card>
            </div>
        </>
    );
};
