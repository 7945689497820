import React, {useEffect, useState} from 'react';
import {AgencyService} from '../service';
import Tooltip from 'react-bootstrap/Tooltip';
import {OverlayTrigger} from 'react-bootstrap';
import {useAgencyCreators} from '../../../shared/hooks/useAgencyCreators';
import {User} from '../../../model/user/User';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {setSelectetInfluencer} from '../../../store/agency/agencySlice';
import {CustomImageHandler} from '../../../shared/components/CustomImageHandler';

export function AgencyCreatorHeaderList() {
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);
    const {
        users,
    } = useAgencyCreators();
    const dispatch = useDispatch();
    const selectInfluencer = (user: Partial<User>) => {
        if (selectedInfluencer?.id !== user.id) {
            dispatch(setSelectetInfluencer(user));

        }
    };
    const isSelectedOutOfList = !!selectedInfluencer?.id && users?.slice(0, 6).every(user => user.id !== selectedInfluencer?.id);
    return (
        <>
            {users?.slice(0, 6).map((item, index) => (
                <div onClick={() => selectInfluencer(item)} key={item.id} className="mr-2 cursor-pointer">
                    <OverlayTrigger
                        key={`${item.id + item?.firstName}`}
                        overlay={
                            <Tooltip placement="bottom" id={`tooltip-bottom`}>
                                {!!item.displayName ? item.displayName : (item.firstName + ' ' + item.lastName)}
                            </Tooltip>
                        }
                    >
                        <div
                            className={item?.id === selectedInfluencer?.id ? 'position-relative user-border radius-50' : 'position-relative'}>
                            <CustomImageHandler
                                photoPath={item?.profilePhoto?.thumbnail}
                                classes="agency-filter-rounded-img img-cover"
                                altTag="Campaign placeholder"
                                placeholder="/icons/UserPlaceholder.png"
                            />
                            {item?.id === selectedInfluencer?.id &&
                                <p className="active-user mb-0">Active</p>
                            }
                            {item.hasRedDot && <div id="bullet" className="user-red-dot"/>}
                        </div>
                    </OverlayTrigger>
                </div>
            ))
            }
            {isSelectedOutOfList &&
                <div onClick={() => selectInfluencer(selectedInfluencer)} className="mr-2 cursor-pointer">
                    <OverlayTrigger
                        overlay={
                            <Tooltip placement="bottom" id={`tooltip-bottom`}>
                                {selectedInfluencer.firstName} {''} {selectedInfluencer.lastName}
                            </Tooltip>
                        }
                    >
                        <div
                            className={'position-relative user-border radius-50'}>
                            <CustomImageHandler
                                photoPath={selectedInfluencer?.profilePhoto?.path}
                                thumbnailPath={selectedInfluencer?.profilePhoto?.thumbnail}
                                classes="agency-filter-rounded-img img-cover"
                                altTag="Campaign placeholder"
                                placeholder="/icons/UserPlaceholder.png"
                            />
                            <p className="active-user mb-0">Active</p>
                            {selectedInfluencer.hasRedDot && <div id="bullet" className="user-red-dot"/>}
                        </div>
                    </OverlayTrigger>
                </div>}
        </>
    );
}
