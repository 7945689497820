import i18n from 'i18next';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ProtectedRoute} from '../authentication/ProtectedRoute';
import {AuthService} from '../authentication/service';
import {UserRoles} from '../enum/UserRoles';
import LoadingScreen from '../shared/components/LoadingScreen';
import {useSearchQuery} from '../shared/hooks/useSearchQuery';
import {actions} from '../store/authentication/authRedux';
import {EnumService} from '../store/enums/enumService';
import enumsSlice from '../store/enums/enumsRedux';
import registrationSlice from '../store/register/brand/registrationSlice';
import {IAllStates} from '../store/rootReducer';
import {ErrorToast} from '../utils/toasters';
import {AgencyRoutes} from './agency/routes';
import {BrandRoutes} from './brand/routes';
import {InfluencerRoutes} from './influencer/routes';
import {ImpersonateRoutes} from './shared/impersonate/routes';
import GlobalPreferencesModal from './shared/user/components/GlobalPreferencesModal';
import {UserService} from './shared/user/service';
import {AppReloadVersionModal} from '../shared/components/AppReloadVersionModal';
import {SuspendedUserModal} from '../shared/components/alert/SuspendedUserModal';

function App() {
    const lang = useSearchQuery('lang');
    const {user} = useSelector((state: IAllStates) => state.auth, shallowEqual);
    const {activeCampaignId} = useSelector((state: IAllStates) => state.selectedCampaign, shallowEqual);
    const {isLoading} = useSelector((state: IAllStates) => state.loading);
    const [isGlobalPreferencesOpened, setIsGlobalPreferencesOpened] = useState(false);
    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const campaignId = localStorage.getItem('activeCampaignId');
    // GET ENUMS
    useEffect(() => {
        EnumService.getEnums().then((data) => {
            dispatch(enumsSlice.actions.setEnums(data));
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (token) {
            AuthService.getPersonalInfo({campaignId: activeCampaignId ?? campaignId, token: JSON.parse(token) ?? ''})
                .then((data) => {
                    dispatch(actions.setUser(data));
                    // setShowSuspendedModal(data?.isSuspended ?? false);
                });
        }
        // eslint-disable-next-line
    }, [JSON.stringify(activeCampaignId)]);

    useEffect(() => {
        user?.id && UserService.getGlobalPreference().then(response => {
            const areNotificationsOff = !response.isNotificationByEmailRequested && !response.isNotificationInBrowserRequested && response.isUnsubscribed && !response.isNotificationByMentionRequested;
            const globalPreferencesPastTime = localStorage.getItem('globalPreferencesPastTime');
            const today = new Date();
            const savedDate = globalPreferencesPastTime ? JSON.parse(localStorage.getItem('globalPreferencesPastTime') ?? '') : new Date(today.setDate(today.getDate() - 31));
            const diffTime = moment(new Date())?.diff(savedDate, 'day');
            if (areNotificationsOff && (diffTime > 29)) {
                setIsGlobalPreferencesOpened(true);
                localStorage.setItem('globalPreferencesPastTime', JSON.stringify(new Date()))
            }
        }).catch(error => ErrorToast(error));
        setShowSuspendedModal(user?.isSuspended ?? false);
        return () => setShowSuspendedModal(false);
    }, [user?.id]);

    // SET LANGUAGE
    useEffect(() => {
        const languageToSave = lang || user?.locale;
        i18n.changeLanguage(languageToSave);
    }, [user, lang]);

    function checkRole() {
        if (user?.userType === UserRoles.INFLUENCER) {
            return <ProtectedRoute><InfluencerRoutes/></ProtectedRoute>;
        } else if ([UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(user?.userType as any) ) {
            return <ProtectedRoute><AgencyRoutes/></ProtectedRoute>;
        } else if ([UserRoles.BRAND, UserRoles.BRAND_MASTER].includes(user?.userType as any)) {
            return <ProtectedRoute><BrandRoutes/></ProtectedRoute>;
        }
        return <ProtectedRoute><></>
        </ProtectedRoute>
    }

    useEffect(() => {
        if (user?.userType === 'influencer') {
            document.body.classList.add('influencer-side');
        } else {
            document.body.classList.remove('influencer-side');
        }
    }, [user?.userType]);

    useEffect(() => {
        document.title = 'App | influence.vision';
    }, [])

    useEffect(() => {
        dispatch(registrationSlice.actions.clearRegisterUser()); // clear redux state if user is on login screen
    }, [])

    return (
        <>
            <GlobalPreferencesModal show={isGlobalPreferencesOpened} errorMessage={t('general.updateNotifications')}
                                    closeModal={() => setIsGlobalPreferencesOpened(false)}/>
            <SuspendedUserModal show={showSuspendedModal}/>
            {checkRole()}
            <ImpersonateRoutes/>
            <ToastContainer/>
            {isLoading && <LoadingScreen/>}
            <AppReloadVersionModal/>
        </>
    );
}

export default App;
