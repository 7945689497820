import i18n from 'i18next';
import React, {useCallback, useEffect, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {getCorrectRegisterInfo, getCorrectRegisterRoute} from '../shared/functions/Functions';
import {useSearchQuery} from '../shared/hooks/useSearchQuery';
import {IAllStates} from '../store/rootReducer';
import {availableLanguages} from '../utils/i18n';
import {ErrorToast} from '../utils/toasters';
import {AuthRoutes} from './routes';
import {AuthService} from './service';
import registrationSlice from '../store/register/brand/registrationSlice';

export function AuthPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentScreen, setCurrentScreen] = useState('login');
  const isNotLoginScreen = currentScreen !== 'login';
  const currentStepId = isNotLoginScreen && (location?.pathname?.split('/').at(-1) ?? '');
  const token = useSearchQuery('token');
  const agencyToken = useSearchQuery('agencyToken');
  const utmCampaign = useSearchQuery('utm_campaign');
  const [isLanguageChanged, setIsLanguageChanged] = useState(false);
  const savedBrandData = useSelector((state: IAllStates) => state.registration);
  const savedInfluencerData = useSelector((state: IAllStates) => state.influencerRegistration);

  const handleChangeLanguage = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage);
    setIsLanguageChanged(prev => !prev);
  };

  useEffect(() => {
    if (utmCampaign) {
      localStorage.setItem('utmCampaign', utmCampaign);
    }
  }, [utmCampaign]);

  const LanguageDropdown = useCallback(
    () => <div className="auth-language-switch">
      <Dropdown className="mx-2">
        <Dropdown.Toggle
          className=""
          variant="light"
          id="dropdown-basic"
        >
          {i18n.language.toUpperCase()}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {availableLanguages?.filter(language => language !== i18n.language)?.map(language => (
            <Dropdown.Item key={language}
                           onClick={() => handleChangeLanguage(language)}>
              {language.toUpperCase()}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>,
    // eslint-disable-next-line
    [isLanguageChanged],
  );

  useEffect(() => {
    if (location?.pathname.includes('/brand/register')) {
      setCurrentScreen('registerBrand');
    } else if (location?.pathname?.includes('/brand/employee/register')) {
      setCurrentScreen('registerEmployee');
    } else if (location?.pathname?.includes('/influencer/register') && !!agencyToken?.length) {
      setCurrentScreen('registerAgencyInfluencer');
    } else if (location?.pathname?.includes('/influencer/register')) {
      setCurrentScreen('registerInfluencer');
    } else if (location?.pathname?.includes('/agency/register')) {
      setCurrentScreen('registerAgency');
    } else {
      setCurrentScreen('login');
    }
    // eslint-disable-next-line
  }, [location?.pathname]);

  useEffect(() => {
    if (currentScreen === 'registerInfluencer' || currentScreen === 'registerAgencyInfluencer') {
      if (!savedInfluencerData?.user?.isEnabled && +currentStepId > 2) {
        history.push(`${getCorrectRegisterRoute(currentScreen)}/2?token=${token}`);
      }
    } else {
        if (!savedBrandData.isEnabled && +currentStepId > 2) {
          history.push(`${getCorrectRegisterRoute(currentScreen)}/2?token=${token}`);
        }
      }
      if (Number(currentStepId) >= 2 && currentScreen !== 'registerInfluencer' && currentScreen !== 'registerAgencyInfluencer' && isNotLoginScreen) {
        AuthService.getUserDataById(currentScreen, token as string).then(response => {
          dispatch(registrationSlice.actions.setRegisterUser(response));
        }).catch(error => ErrorToast(error));
      }
      // eslint-disable-next-line
    }, [currentStepId, isNotLoginScreen, currentScreen]);

    useEffect(() => {
      if (savedBrandData?.registrationStep && isNotLoginScreen && currentScreen !== 'registerInfluencer') {
        if (savedBrandData.registrationStep < +currentStepId) {
          history.push(`${getCorrectRegisterRoute(currentScreen)}/${savedBrandData.registrationStep + 1}?token=${token}`);
        }
      }
      // eslint-disable-next-line
    }, [savedBrandData?.registrationStep, savedBrandData.isRegistrationComplete]);
    return (
      <div className="ps-md-0">
        <div className="row g-0">
          {getCorrectRegisterInfo(currentScreen)}
          <div className="col-md-8 col-lg-6 max-height-100vh overflow-auto">
            <div className={`login d-flex ${!isNotLoginScreen ? 'align-items-center' : ''} py-5`}>
              <div className="row">
                <div className={`col-md-9 col-lg-10 mx-auto text-center px-0`}>
                  <AuthRoutes/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LanguageDropdown/>
      </div>
    );
  }
