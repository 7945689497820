import React, {useEffect, useState} from 'react';
import AudienceData from './AudienceData';
import AudienceDataChart from './AudienceDataChart';
import {Card, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IAudienceDataModal, IChartData} from '../../../dto/IAudienceDataModal';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';
import {BookingService} from '../../../../service';
import {ErrorToast} from '../../../../../../../../utils/toasters';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {IAudienceCharts} from '../../../dto/IAudienceCharts';
import {getFilteredChartsData} from '../../../../../../../../shared/functions/Functions';
import {AudienceCharts} from './AudienceCharts';
import AudienceKpiScreenshots from './AudienceKpiScreenshots';
import { User } from '../../../../../../../../model/user/User';

export const chartColors = [
  '#05BBC9',
  '#952CB7',
  '#FC9D79',
  '#e2051c',
  '#0fda65',
  '#197ee1',
  '#181c32',
];

const EnhancedAudienceData = ({user}: {user: User}) => {
  const {activeTabModal, userId} = useSelector((state: IAllStates) => state.offerBrand);
  const [audienceData, setAudienceData] = useState<IAudienceDataModal>();
  const [allCharts, setAllCharts] = useState<IAudienceCharts>({
    engagementChart: [],
    followersChart: [],
    followingChart: [],
    likesChart: [],
  });
  const {t} = useTranslation();

  const [value, setValue] = React.useState('0');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (activeTabModal === '2') {
      BookingService.getAudienceDataModal(userId)
        .then((response) => {
          setAudienceData(response);
          setAllCharts(getFilteredChartsData(response.chartData));
        }).catch(err => ErrorToast(err));

    }
    // eslint-disable-next-line
  }, [userId]);
  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Card className="mb-4 ">
          <Card.Body>
            <TabContext
              value={value}>
              <TabList onChange={handleChange}>
                {audienceData?.platformDataArray.map((platformData, index) => {
                  return (
                    <Tab
                      key={index}
                      label={`${platformData?.mainPlatform?.name}`}
                      value={String(index)}
                    />
                  );
                })}
              </TabList>
              {audienceData?.platformDataArray.map((platformData, index) => {
                return (
                  <TabPanel key={index} value={String(index)}>
                    <AudienceData audienceData={platformData}/>
                  </TabPanel>
                );
              })}
            </TabContext>
          </Card.Body>
        </Card>
      </Box>
      {!!user?.userKpiScreenshots?.length && (
        <AudienceKpiScreenshots user={user}/>
      )}
      <AudienceCharts charts={allCharts} chartDates={audienceData?.chartDates ?? []}/>
    </>
  );
};

export default EnhancedAudienceData;
