import moment from 'moment/moment';
import React, {SetStateAction, useEffect, useState,} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {TFunction} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Campaign} from '../../../../../../../model/campaign/Campaign';
import {IOfferItems} from '../../../../../../../model/offer/IOfferItems';
import {User} from '../../../../../../../model/user/User';
import {CustomAutocomplete} from '../../../../../../../shared/components/Autocomplete';
import CustomDatePicker from '../../../../../../../shared/components/DatePicker';
import {
    formatDate,
    getLocalDateWithoutTimezone, isSelectedDateValid,
    removeTimeZoneFromDate
} from '../../../../../../../shared/functions/Functions';
import {manageBrand} from '../../../../../../../store/brand/manageBrandSlice';
import {IAllStates} from '../../../../../../../store/rootReducer';
import {ErrorToast} from '../../../../../../../utils/toasters';
import {ManageService} from '../../../service';
import {SelectedUserCard} from '../SelectedUserCard';

export interface iCalendarModalProps {
    show: boolean;
    closeModal: () => void;
    onSend: () => void;
    t: TFunction<'translation'>;
    state: any;
    setState: React.Dispatch<SetStateAction<any>>;
    setSelectedEventId?: React.Dispatch<SetStateAction<number>>;
    campaignId?: number;
    triggerUpdate?: boolean;
    campaign?: Campaign;
}

export const CalendarCreateModal = ({
                                        show,
                                        closeModal,
                                        campaign,
                                        t,
                                        state,
                                        setState,
                                        onSend,
                                        campaignId,
                                        triggerUpdate,
                                    }: iCalendarModalProps) => {
    const dispatch = useDispatch();
    const {selectedInfluencer: selectedCardInfluencer} = useSelector((state: IAllStates) => state.manageBrand);
    const [isFieldDisabled, setIsFieldDisabled] = useState(false);
    const [filterInfluencers, setFilterInfluencers] = useState({
        page: 1,
        perPage: 10,
        userId: '',
        displayName: '',
    });

    const [listOfAllInfluencers, setListOfAllInfluencers] = useState<User[]>([]);
    const [selectedInfluencer, setSelectedInfluencer] = useState<User>();
    const handleChangeTimepicker = (newValue: Date | null) => {
        const value = getLocalDateWithoutTimezone(newValue as Date);
        setState((prev: { postingDate: string }) => ({
            ...prev,
            postingDate: value,
        }));
    };

    const handleChange = (paramKey: string, paramValue: number | string) => {
        setState((prev: object) => ({
            ...prev,
            [paramKey]: +paramValue,
        }));
    };

    useEffect(() => {
        if (!!state?.userId) {
            setIsFieldDisabled(true);
        } else {
            setIsFieldDisabled(false);
        }
        // eslint-disable-next-line
    }, [JSON.stringify(state)]);

    useEffect(() => {
        campaignId && ManageService.getFilterInfluencers(+campaignId, filterInfluencers).then(res => {
            setListOfAllInfluencers(res.data);
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [JSON.stringify(filterInfluencers), campaignId, triggerUpdate]);


    useEffect(() => {
        if (state?.userId) {
            const copy = [...listOfAllInfluencers];
            const selectedUser = copy?.find(user => user?.id === +state?.userId);
            if (selectedUser?.id) {
                setSelectedInfluencer(selectedUser);
            } else {
                ManageService.getFilterInfluencers(campaignId ?? 0, {
                    ...filterInfluencers,
                    userId: `${state?.userId}`
                }).then(res => {
                    setSelectedInfluencer(res?.data[0]);
                }).catch(error => ErrorToast(error));
            }
        }
        // eslint-disable-next-line
    }, [JSON.stringify(state?.userId)]);


    const dropdownWithUsers = (listOfInfluencers: any) => {
        return (
            <CustomAutocomplete inputLabelTranslation={t('brand.campaign.manage.influencerName')}
                                isWithImage={true} setFilterState={setFilterInfluencers}
                                changeEventKey="displayName" handleChangeParams={handleChange}
                                dataArray={listOfInfluencers?.filter((influencer: any) => influencer?.offers?.[0]?.offerItems?.some((offer: IOfferItems) => offer.nbPosts > (offer?.eventNbPosts ?? 0)))}
                                inputLabel="firstName" photoLabel="profilePhoto.path"
                                queryFilterKey="userId"
                                selectedStateImage={selectedInfluencer?.profilePhoto?.path as string}/>
        );
    };

    const allPossiblePlatforms = selectedInfluencer?.offers?.[0]?.offerItems?.sort((a, b) => b.socialPlatform.name > a.socialPlatform.name ? 1 : -1);
    const selectedPlatform = allPossiblePlatforms?.find(platform => platform.id === state?.offerItemId);

    useEffect(() => {
        if (show && listOfAllInfluencers?.filter((influencer: any) => influencer?.offers?.[0]?.offerItems?.some((offer: IOfferItems) => offer.nbPosts > (offer?.eventNbPosts ?? 0)))?.length === 1) {
            setState((prev: object) => ({...prev, userId: listOfAllInfluencers?.[0]?.id}))
        }
        // eslint-disable-next-line
    }, [JSON.stringify(listOfAllInfluencers), show]);

    useEffect(() => {
        if (selectedCardInfluencer?.user?.id) {
            handleChange('userId', +selectedCardInfluencer?.user?.id)
            dispatch(manageBrand.actions.setSelectedInfluencer(undefined));
        }
        // eslint-disable-next-line
    }, [selectedCardInfluencer?.user?.id])

    const getFeedbackLoopDate = (isReviewed = false, reviewDays = 1, postingDate: string | undefined) => {
        if (isReviewed) { // if campaign is reviewed substcract reviews days
            return moment(postingDate).subtract(reviewDays, 'days').toISOString()
        }
        return postingDate // use selected calendar date
    }
    console.log(state?.postingDate, campaign?.publishFrom, campaign?.publishFrom, 'aaa')
    return (
        <Modal
            show={show}
            onHide={closeModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="height-95vh"
        >
            <Modal.Header>
                <Modal.Title className="mt-3 w-100">
                    <h4 className="border-bottom m-0 pb-2">
                        {`${t('brand.campaign.manage.modal.schedulePosting')}`}
                    </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 my-2">
                        {!isFieldDisabled ? (
                            <>{dropdownWithUsers(listOfAllInfluencers)}</>
                        ) : (
                            <SelectedUserCard
                                profilePhoto={selectedInfluencer?.profilePhoto?.path as string}
                                totalPosts={selectedInfluencer?.offers?.[0]?.scheduledPosts?.totalNbPosts as number}
                                totalScheduledPosts={selectedInfluencer?.offers?.[0]?.scheduledPosts?.created as number}
                                displayName={`${selectedInfluencer?.firstName ?? ''} ${selectedInfluencer?.lastName}`}
                                closeHandler={() => setState({})}
                            />
                        )}
                    </div>
                    {isFieldDisabled && (
                        <>
                            <div className="col-12 my-2">
                                <CustomAutocomplete
                                    dataArray={allPossiblePlatforms as IOfferItems[]}
                                    isWithImage={true} inputLabel="socialPlatform.name"
                                    photoLabel="socialPlatform.path"
                                    selectedStateImage={selectedPlatform?.socialPlatform?.path as string}
                                    queryFilterKey="offerItemId"
                                    inputLabelTranslation={`${t('brand.campaign.manage.postingType')}`}
                                    handleChangeParams={handleChange}/>
                            </div>
                            <div className="col-12 my-2">
                                <CustomDatePicker label={t('brand.campaign.manage.postingDate')}
                                                  disableFrom={campaign?.publishFrom ?? ''}
                                                  disableTo={removeTimeZoneFromDate(campaign?.publishTo ?? '')}
                                                  value={state?.postingDate} onChange={handleChangeTimepicker}/>
                                {!isSelectedDateValid(state?.postingDate, campaign?.publishFrom, campaign?.publishTo) &&
                                    <p className="text-danger">
                                        {t('influencer.planner.notInRange')}
                                        {formatDate(campaign?.publishFrom as string)} - {formatDate(campaign?.publishTo as string)}
                                    </p>}
                            </div>

                            <div className="col-12 my-2">
                                <CustomDatePicker label={t('brand.campaign.manage.feedBackLoopEnddate')} disabled
                                                  value={getFeedbackLoopDate(campaign?.isReviewed, campaign?.reviewDays, state?.postingDate)}
                                                  onChange={handleChangeTimepicker}/>
                            </div>
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-primary"
                    className="btn-sm"
                    onClick={closeModal}
                >
                    {t('general.button.close')}
                </Button>
                <Button variant="primary" className="btn-sm" onClick={onSend}
                        disabled={!isSelectedDateValid(state?.postingDate, campaign?.publishFrom, campaign?.publishTo)}>
                    {t('general.button.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
