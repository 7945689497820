import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, DropdownButton, Row} from 'react-bootstrap';
import {BsSortDown, BsSortUp} from 'react-icons/bs';
import {useTranslation} from 'react-i18next';
import {IAgencyDashFilter} from '../dto/IAgencyDashFilter';
import {TextField} from '@material-ui/core';
import _debounce from 'lodash/debounce';
import {AgencyService} from '../../service';
import {ErrorToast} from '../../../../utils/toasters';
import {ICompanyUser} from '../../../shared/user/dto/ICompanyUser';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {EnvironmentType} from '../../../../enum/EnvironmentType';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {UserRoles} from "../../../../enum/UserRoles";

interface IAgencyFilterProps {
    filterParams: IAgencyDashFilter;
    setFilterParams: React.Dispatch<React.SetStateAction<IAgencyDashFilter>>;
}

export function AgencyFilter({filterParams, setFilterParams}: IAgencyFilterProps) {
    const {t} = useTranslation();
    const [companyUsers, setCompanyUsers] = useState<ICompanyUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<ICompanyUser>();
    const {user} = useSelector((state: IAllStates) => state.auth);


    useEffect(() => {
        AgencyService.getAgencyCompanyUsers({isEnabled: true})
            .then((data) => {
                setCompanyUsers(data);
            }).catch(error => ErrorToast(error));
    }, []);

    useEffect(() => {
        setSelectedUser(companyUsers?.find(u => Number(u?.id) === Number(filterParams?.assignedManagerId)));
        // eslint-disable-next-line
    }, [JSON.stringify(filterParams?.assignedManagerId)]);

    const changeHandler = _debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterParams({
            ...filterParams,
            page: 1,
            [event.target.name]: event.target.value,
        });
    }, 300);

    const handleUsers = (paramKey: string, paramValue: string | number) => {
        setFilterParams({
            ...filterParams,
            page: 1,
            [paramKey]: paramValue,
        });
    };

    return (
        <>
            <Row className="mb-4">
                {user.userType === UserRoles.AGENCY && <Col md={4}>
                    <TextField
                        label={t('agency.filter.name')}
                        variant="outlined"
                        size="small"
                        name="displayName"
                        onChange={changeHandler}
                        className="w-100 mb-2 my-md-0"
                    />
                </Col>}
                {user.userType === UserRoles.AGENCY_MASTER && <Col md={4}>
                    <CustomAutocomplete dataArray={companyUsers} isWithImage={true} isMultiple={false}
                                        inputLabel="firstName"
                                        queryFilterKey="assignedManagerId" photoLabel="profilePhoto.path"
                                        selectedStateImage={selectedUser?.profilePhoto?.path as string}
                                        inputLabelTranslation={t('agency.filter.managing')}
                                        handleChangeParams={handleUsers}/>
                </Col>}
                <Col md={8} className="pr-0">
                    <div className="d-flex align-items-center justify-content-end">
                        <Button
                            onClick={() =>
                                setFilterParams({
                                    ...filterParams,
                                    sortType: filterParams.sortType === 'DESC' ? 'ASC' : 'DESC',
                                })
                            }
                            variant="transparent"
                        >
                            {filterParams.sortType === 'ASC' ? <BsSortUp/> : <BsSortDown/>}
                        </Button>
                        <DropdownButton
                            className="mr-2 pl-1 font-weight-normal"
                            variant="transparent"
                            title={
                                <span>{t(!filterParams?.sortCriteria ? 'general.button.sort_by' : `brand.campaign.book.offer.filters.${filterParams?.sortCriteria}`)}</span>}
                        >
                            {filterParams?.sortCriteria && <Dropdown.Item
                                onClick={() =>
                                    setFilterParams({
                                        ...filterParams,
                                        sortCriteria: '',
                                        page: 1,
                                    })
                                }
                            >
                                {t('agency.filter.clear')}
                            </Dropdown.Item>}
                            <Dropdown.Item
                                onClick={() =>
                                    setFilterParams({
                                        ...filterParams,
                                        sortCriteria: 'totalFollowers',
                                        page: 1,
                                    })
                                }
                            >
                                {t('agency.filter.totalFollowers')}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    setFilterParams({
                                        ...filterParams,
                                        sortCriteria: 'createdAt',
                                        page: 1,
                                    })
                                }
                            >
                                {t('agency.filter.createdAt')}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    setFilterParams({
                                        ...filterParams,
                                        sortCriteria: 'totalEngagements',
                                        page: 1,
                                    })
                                }
                            >
                                {t('agency.filter.totalEngagements')}
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </Col>
            </Row>
        </>
    );
}
