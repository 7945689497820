import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Route} from 'react-router';
import {IAllStates} from '../store/rootReducer';
import {AuthPage} from './AuthPage';

interface IProtectProps {
    children: JSX.Element;
}

export const ProtectedRoute = ({children}: IProtectProps) => {
    const {isAuthorized, isVerified} = useSelector((state: IAllStates) => ({
        isAuthorized: !!state.auth.user,
        isVerified: state.auth.isVerified,
    }), shallowEqual);
    if (!isAuthorized || !isVerified) {
        return (
            <Route>
                <AuthPage/>
            </Route>
        );
    }
    return children;
};
