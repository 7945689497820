import React, {useState} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {IAddMoreCreatorsModal} from '../dto/IAddMoreCreatorsModal';
import {useTranslation} from 'react-i18next';
import {TextField} from '@material-ui/core';
import {emailRegex} from '../../../../shared/functions/Functions';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../utils/toasters';
import {AgencyService} from '../../service';
import {IRegisterAgencyInvite} from '../dto/IRegisterAgency';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';

export function AddMoreCreatorsModal({show, closeModal, setUpdate}: IAddMoreCreatorsModal) {
  const {t} = useTranslation();
  const {user} = useSelector((reduxState: IAllStates) => reduxState.auth);

  const [inviteUsers, setInviteUsers] = useState<IRegisterAgencyInvite>({
    emailsArray: [''],
    userId: user.id,
  });

  const inviteUsersRequest = () => {
    const allElements = inviteUsers;
    const lastElement = allElements.emailsArray.pop();
    if (lastElement === '') {
      allElements.emailsArray.slice(0, -1);
    }
    AgencyService.inviteUsers(allElements)
      .then((then) => {
          SuccessToast(t('brand.campaign.book.invite.successfully_invite_user'));
          setInviteUsers({...inviteUsers, emailsArray: ['']});
          closeModal();
          if (setUpdate) {
            setUpdate(prevState => !prevState);
          }
      }).catch(err => ErrorToast(err));
  };

  const increaseFields = () => {
    const copy = [...inviteUsers.emailsArray];
    if (!emailRegex.test(copy?.at(-1) as string)) {
      return WarningToast(t('general.toasts.warnValidEmail'));
    }
    copy.push('');
    setInviteUsers(prev => ({...prev, emailsArray: copy}));
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const {value} = event.target;
    setInviteUsers(prev => ({
      ...prev,
      emailsArray: inviteUsers.emailsArray.map((email, idx) => idx === index ? value : email),
    }));
  };


  return (
    <>
      <Modal show={show}
             onHide={closeModal}
             className="modal-dark"
             size="sm"
             centered={true}
      >
        <Modal.Body>
          <Row className="justify-content-center">
            <Col md={12} xs={12}>
              <h5 className="text-left pb-2 border-bottom">
                {t('agency.incite_creators')}
              </h5>
              <h6 className="text-muted mt-2">{t('agency.incite_creators_desc')}</h6>
              {inviteUsers?.emailsArray?.map((e, index) => <TextField
                key={index}
                label="E-mail"
                variant="outlined"
                defaultValue={e}
                name="emailsArray"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, index)}
                size="small"
                required
                className="w-100 my-2"
              />)}
            </Col>
            <Col md={12} xs={12} className="text-left mt-2">
              <Button variant="outline-primary" onClick={increaseFields}>
                {t('general.button.add')}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button onClick={closeModal} variant="outline-primary" className="btn-sm mx-2">
            {t(`general.button.cancel`)}
          </Button>
          <Button disabled={inviteUsers?.emailsArray?.length <= 1}
                  className="btn-sm"
                  onClick={inviteUsersRequest}
          >
            {t(`general.button.invite`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
