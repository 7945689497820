import React, {useState} from 'react';
import {Button, Card, Col, Form, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {AuthService} from '../service';
import {IAllStates} from '../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../utils/toasters';
import {actions} from '../../store/authentication/authRedux';
import {TextField} from '@material-ui/core';
import {StepperButtons} from '../register/brand/components/StepperButtons';

export function VerificationPage() {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const {user} = useSelector((state: IAllStates) => state?.auth);

    const [confirmationToken, setConfirmationToken] = useState({
        twoFactorToken: '',
    });

    const resendVerify = () => {
        setIsLoading(true);
        AuthService.resetVerifyCode2FO(user?.email).then(response => {
            SuccessToast(t('auth.register.brand.step1.pleaseCheckEmail'));
        }).catch(error => ErrorToast(error)).finally(() => setIsLoading(false));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setConfirmationToken(prev => ({...prev, [name]: value}));
    };

    function onSubmit(event: React.FormEvent<HTMLFormElement | EventTarget & HTMLElement>) {
        event.preventDefault();
        AuthService.twoFactorAuthVerify(user?.twoFactorUUID, confirmationToken.twoFactorToken)
            .then((data) => {
                dispatch(actions.setIsVerified({isVerified: true}));
            }).catch(err => ErrorToast(err));
    }

    return (
        <>
            <Form className="w-100" onSubmit={(e) => onSubmit(e)} 
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") onSubmit(e);
                }}
            >
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        <img alt="Verifaid img" src="/icons/profile/verified.png"/>
                        <h3 className="mt-4">Verify your account</h3>
                        <p className="text-muted">Enter the code received in your E-Mail:<br/>{user?.email}</p>
                    </div>

                    <div className="col-md-7">
                        <TextField
                            label="Code"
                            value={confirmationToken.twoFactorToken}
                            onChange={handleChange}
                            name="twoFactorToken"
                            variant="outlined"
                            size="small"
                            required
                            className="w-100 my-2"
                        />
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">
                                {t('auth.register.brand.step2.checkSpamFolder')}
                            </span>
                            <a href="#1"
                               className="d-flex align-items-center justify-content-center"
                               onClick={() => resendVerify()}>
                                {isLoading ? <>
                                    Loading... <Spinner animation="border" size="sm" role="status" className="ml-1"/>
                                </> : t('auth.register.brand.step2.reSend')}
                            </a>
                        </div>
                    </div>
                    <div className="col-md-7 text-right mt-4">
                        <Button disabled={confirmationToken.twoFactorToken.length < 4} type='submit'
                                variant="outline-primary"
                                size="sm">Verify</Button>
                    </div>
                </div>
            </Form>

        </>
    )
};