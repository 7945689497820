import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Campaign} from '../../../model/campaign/Campaign';
import {useSearchQuery} from '../../../shared/hooks/useSearchQuery';
import {setSelectMatchedUser} from '../../../store/agency/agencyOfferSlice';
import {ErrorToast} from '../../../utils/toasters';
import {IOfferManagementFilter} from '../../influencer/offer/dto/IOfferManagementFilter';
import {AgencyService} from '../service';
import {IOfferManagement} from '../../influencer/offer/dto/IOfferManagement';
import {OffersFilter} from '../../influencer/offer/components/OffersFilter';
import EmptyState from '../../../shared/components/placeholder/EmptyState';
import {OffersCardItem} from '../../influencer/offer/components/OffersCardItem';
import {Col, Row} from 'react-bootstrap';
import {OffersIcon} from '../../../shared/Icons';
import {OffersMobileTabs} from '../../influencer/offer/components/OffersMobileTabs';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {AgencyInfluencerOfferModal} from './modal';

interface ILocationProps {
    selected: 'to-do' | 'in-progress';
}

export function AgencyOfferManagement() {
    const campaignQueryId = useSearchQuery('campaignId');
    const userQueryId = useSearchQuery('userId');
    const history = useHistory();
    const dispatch = useDispatch();
    const [offerFilterParams, setOfferFilterParams] = useState<IOfferManagementFilter>({
        name: '',
        status: '',
        isCharity: false,
        sortBy: 'date',
        sortType: 'DESC',
    });
    const [updateList, setUpdateList] = useState(false);
    const {state} = useLocation();
    const isMobile = window.innerWidth < 540;
    const [showModal, setShowModal] = useState(false);
    const [campaignId, setCampaignId] = useState('');
    const [campaignTitle, setCampaignTitle] = useState('');
    const {t} = useTranslation();
    const [offerManagement, setOfferManagement] = useState<IOfferManagement>();
    const [tab, selectTab] = useState((state as ILocationProps)?.selected as string ?? 'to-do');
    const {selectedInfluencer} = useSelector((reduxState: IAllStates) => reduxState.agency);

    useEffect(() => {
        AgencyService.getOfferList({...offerFilterParams, influencerId: selectedInfluencer?.id})
            .then(((response) => {
                setOfferManagement(response);
            })).catch(err => ErrorToast(err));
    }, [offerFilterParams, selectedInfluencer, updateList, showModal]);

    const handleTab = (event: React.SyntheticEvent, newValue: string) => {
        selectTab(newValue);
    };

    const displayOffers = (tabName: string) => {
        if (tabName === 'to-do') {
            if (!offerManagement?.todoOffers?.campaigns?.length) {
                return [<EmptyState iconPath="/icons/received.png" key="emptytodo"
                                    addClass="mb-4 col-8 text-center mx-auto"
                                    desc={t('influencer.offer_management.empty_to_do')}
                                    btnText={t('influencer.dashboard.explore')}
                                    navigateTo="/agency/explore"/>];
            }
            return (
                offerManagement?.todoOffers?.campaigns?.map((campaign) => (
                    <OffersCardItem key={campaign?.id} setCampaignId={setCampaignId} setCampaignTitle={setCampaignTitle}
                                    setShowModal={setShowModal}
                                    showModal={showModal} isTodo={true}
                                    campaign={campaign}
                                    isAgencyCard={true}
                    />
                ))
            );
        } else if (tabName === 'in-progress') {
            if (!offerManagement?.inProgressOffers?.campaigns?.length) {
                return [<EmptyState iconPath="/icons/sent.png" key="emptyinprogress"
                                    addClass="mb-4 col-8 text-center mx-auto"
                                    desc={t('influencer.offer_management.empty_in_progress')}
                                    btnText={t('influencer.dashboard.explore')}
                                    navigateTo="/agency/explore"/>];
            }
            return (
                offerManagement?.inProgressOffers?.campaigns.map((campaign) => (
                    <OffersCardItem key={campaign?.id} setCampaignId={setCampaignId} setCampaignTitle={setCampaignTitle}
                                    setShowModal={setShowModal}
                                    showModal={showModal}
                                    campaign={campaign}
                                    isAgencyCard={true}
                    />
                ))
            );
        }
    };

    useEffect(() => {
        if (campaignQueryId && userQueryId) {
            const getData = async () => {
                const selectedCampaign = await AgencyService.getAgencyOfferModalDetail(campaignQueryId, Number(userQueryId));
                setCampaignId(String(campaignQueryId));
                setCampaignTitle(selectedCampaign?.title);
                setShowModal(true);
                if (selectedCampaign?.offers[0]?.user) {
                    dispatch(setSelectMatchedUser(selectedCampaign?.offers[0]?.user));
                }
                history.push('/agency/offers');
            }
            getData();
        }
    }, [campaignQueryId && userQueryId])

    return (
        <>
            <OffersFilter offerFilter={offerFilterParams}
                          setOfferFilter={setOfferFilterParams}
                          newDeclined={offerManagement?.declinedOffersDot}
                          isAgency={true}/>
            <AgencyInfluencerOfferModal show={showModal}
                                        setShowModal={setShowModal}
                                        campaignId={campaignId}
                                        updateList={setUpdateList}
            />
            {!isMobile ?
                <>
                    <Row>
                        <Col lg={6}>
                            <div className="d-flex align-items-center mb-4">
                                <OffersIcon width="30" height="30" color="light-gray"/>
                                <h4 className="mb-0 ml-2 text-uppercase">
                                    {t('influencer.offer_management.to_do', {counter: offerManagement?.todoOffers?.campaignsCount})}
                                </h4>
                            </div>
                            {!!displayOffers('to-do')?.length ? displayOffers('to-do') : <div className="card">
                                <div className="card-body"><EmptyState/></div>
                            </div>}
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-center mb-4">
                                <OffersIcon width="30" height="30" color="light-gray"/>
                                <h4 className="mb-0 ml-2 text-uppercase">
                                    {t('influencer.offer_management.in_progress', {counter: offerManagement?.inProgressOffers?.campaignsCount})}
                                </h4>
                            </div>
                            {!!displayOffers('in-progress')?.length ? displayOffers('in-progress') :
                                <div className="card">
                                    <div className="card-body"><EmptyState/></div>
                                </div>}
                        </Col>
                    </Row>
                </> :
                <>
                    <OffersMobileTabs handleTab={handleTab}
                                      countOffer={{
                                          toDo: offerManagement?.todoOffers?.campaignsCount ?? 0,
                                          inProgress: offerManagement?.inProgressOffers?.campaignsCount ?? 0
                                      }}
                                      tab={tab}/>
                    <Row className="mt-2">
                        {displayOffers(tab)}
                    </Row>
                </>
            }

        </>
    );
}
